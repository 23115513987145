<template>
  <div>
    <header class="hero-int">
      <div class="bb-container">
        <div class="hero-int__container">
          <div class="hero-int__left">
            <h1 class="hero-int__title">
              La liste de naissance pour les familles d’aujourd’hui
            </h1>
            <div class="hero-int__actions">
              <bbButton
                label="Commencez votre liste"
                class="button--lg button--accent"
                @click="$router.push({ name: 'creez-votre-liste' })"
              />
              <a @click="$router.push({ name: 'guest', params: { id: exampleListId } })" class="link link--bold link--underline">
                Voir un exemple de liste
              </a>
            </div>
          </div>
          <div class="hero-int__cover">
            <picture>
              <source
                srcset="../../assets/img/covers/cover-fr-hero@2x.png"
                media="(min-width: 1399px) and (min-device-pixel-ratio: 2)"
              />
              <img src="../../assets/img/covers/cover-fr-hero.png" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </header>
    <section class="rate-app">
      <div class="bb-container">
        <div class="rate-app__container">
          <div class="rate-app__title">
            Plus de 50 000 familles ont fait leur liste de naissance avec HelloBB
          </div>
          <div class="rate-app__card">
            <div class="rate-app__item">
              <div class="rate-app__logo">
                <img src="../../assets/img/ico/ico-google-play.svg" alt="Google Play" />
              </div>
              <div class="rate-app__rate">4,8</div>
              <div class="rate-app__star">
                <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
              </div>
            </div>
            <hr />
            <div class="rate-app__item">
              <div class="rate-app__logo">
                <img src="../../assets/img/ico/ico-app-store.svg" alt="Apple App Store" />
              </div>
              <div class="rate-app__rate">4,8</div>
              <div class="rate-app__star">
                <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="tiendas-list">
      <div class="tiendas-list__title">
        Ajoutez des produits de n’importe quelle boutique
      </div>
      <div class="tiendas-list__items">
        <VueSlickCarousel v-bind="shopSlider">
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-amazon.png" />
          </div>
          <div class="tiendas-list__item">
            <img
              loading="lazy"
              src="../../assets/img/logos/logo-fr-berceau-magique.png"
            />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-autour-bebe.png" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-petit-bateau.png" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-vertbaudet.png" />
          </div>
          <div class="tiendas-list__item">
            <img
              loading="lazy"
              src="../../assets/img/logos/logo-fr-maisons-du-monde.png"
            />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-aubert.png" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-orchestra.png" />
          </div>
          <div class="tiendas-list__item">
            <img
              loading="lazy"
              src="../../assets/img/logos/logo-fr-larmoire-de-bebe.png"
            />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-smallable.png" />
          </div>
          <div class="tiendas-list__item">
            <img
              loading="lazy"
              src="../../assets/img/logos/logo-fr-bebe-au-naturel.png"
            />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-fr-kiabi.png" />
          </div>
        </VueSlickCarousel>
      </div>
    </section>
    <section class="hbb-features">
      <div class="bb-container">
        <div class="hbb-features__title">
          La liste de naissance en ligne <span>réinventée</span>
        </div>
        <div class="hbb-features__items">
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-list-heart.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">La plus belle et la plus personnelle</div>
              <div class="hbb-features__sh">
                Ce moment unique mérite quelque chose qui soit à la hauteur.
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-click-mobile.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">Facile à utiliser pour tout le monde</div>
              <div class="hbb-features__sh">
                Aucun problème pour vous et pour les gens avec qui vous partagez vos
                souhaits.
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-gratuit.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">Gratuit et sans engagement</div>
              <div class="hbb-features__sh">
                Faites votre liste gratuitement et sans obligation d’achat.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="app-avanzada">
      <div class="bb-container">
        <div class="app-avanzada__container">
          <aside class="app-avanzada__left">
            <div class="app-avanzada__title">L’application la plus avancée du marché</div>
            <div class="app-avanzada__description">
              Oubliez les systèmes qui n’ont pas été conçus pour votre smartphone et les
              applications aux fonctionnalités limitées.
            </div>
            <div class="app-avanzada__actions">
              <!-- <a href="#" class="link link--bold link--underline">
                Découvrez notre application gratuite
              </a> -->
              Découvrez notre application gratuite avec tout ce dont vous avez besoin pour faire votre liste parfaite.
            </div>
            <div class="app-avanzada__stores" target="_blank">
              <a :href="$t('links.mobileAppUrlAndroid', 'fr-FR')">
                <img
                  loading="lazy"
                  src="../../assets/img/logos/logo-app-google.fr-FR.svg"
                  alt=""
                  width="140"
                  height="40"
                />
              </a>
              <a :href="$t('links.mobileAppUrlIos', 'fr-FR')" target="_blank">
                <img
                  loading="lazy"
                  src="../../assets/img/logos/logo-app-appstore.fr-FR.svg"
                  alt=""
                  width="140"
                  height="40"
                />
              </a>
            </div>
          </aside>
          <picture class="app-avanzada__cover">
            <img
              src="../../assets//img/covers/mockup-llista.png"
              :alt="$t('home2.appTitle')"
            />
          </picture>
        </div>
      </div>
    </section>
    <main class="home-beneficios">
      <div class="bb-container">
        <div class="home-beneficios__items">
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img src="../../assets/img/covers/cover-fr-heart-sale.png" alt="" />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Choisissez le produit de votre choix dans n’importe quelle boutique
              </div>
              <div class="home-beneficios__description">
                Vous avez l’entière liberté pour choisir ce que vous voulez.
              </div>
              <ul class="home-beneficios__list">
                <li>Des produits uniques de petites marques</li>
                <li>Les meilleures offres des grandes boutiques</li>
              </ul>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img
                src="../../assets//img/covers/cover-fr-recibiendo-dinero.png"
                alt="Prête pour recevoir de l'argent dans votre cagnotte."
              />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Prête pour recevoir de l'argent dans votre cagnotte
              </div>
              <div class="home-beneficios__description">
                Votre liste de naissance contient une cagnotte avec laquelle vous pouvez
                collecter de l’argent pour des achats concrets ou des buts plus généraux.
              </div>
              <div class="home-beneficios__actions">
                <div class="link link--bold link--underline" @click="$router.push({name: 'cagnotte-de-naissance' })">
                  Pour plus d'informations
                </div>
              </div>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img
                src="../../assets//img/covers/mockup-aportacions.png"
                alt="Offrez la possibilité de se cotiser pour les achats les plus chers"
              />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Offrez la possibilité de se cotiser pour les achats les plus chers
              </div>
              <div class="home-beneficios__description">
                Votre famille et vos amis pourront faire des contributions partielles pour
                acheter à plusieurs les produits les plus chers.
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
    <div class="feedback-int">
      <div class="bb-container">
        <div class="feedback-int__title">
          Les gentils commentaires de nos utilisateurs
        </div>
        <div class="feedback-int__items">
          <VueSlickCarousel v-bind="reviewsSlider">
            <div class="feedback-int__item">
              <div class="feedback-user">
                <div class="feedback-user__cover">
                  <img src="../../assets/img/covers/testimonial-marta.jpg" alt="" />
                </div>
                <div class="feedback-user__content">
                  <div class="feedback-user__string">
                    “Cette application est géniale pour créer sa liste de naissance ! Elle
                    a beaucoup d’options et elle est très intuitive. Très recommandable.”
                  </div>
                  <div class="feedback-user__name">Marthe</div>
                </div>
              </div>
            </div>
            <div class="feedback-int__item">
              <div class="feedback-user">
                <div class="feedback-user__cover">
                  <img src="../../assets/img/covers/testimonial-estefania.jpg" alt="" />
                </div>
                <div class="feedback-user__content">
                  <div class="feedback-user__string">
                    “Très bonne solution pour simplifier la question des cadeaux à la
                    naissance d’un bébé ! Facile à utiliser et très utile. Je la
                    recommande ❤️.”
                  </div>
                  <div class="feedback-user__name">Stéphanie</div>
                </div>
              </div>
            </div>
            <div class="feedback-int__item">
              <div class="feedback-user">
                <div class="feedback-user__cover">
                  <img src="../../assets/img/covers/testimonial-ichamar.jpg" alt="" />
                </div>
                <div class="feedback-user__content">
                  <div class="feedback-user__string">
                    “Application très utile et intuitive pour faire la wish-list du bébé.
                    Je la recommande à 100 %.”
                  </div>
                  <div class="feedback-user__name">Pierre</div>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <section class="acomp">
      <div class="bb-container">
        <div class="acomp__container">
          <div class="acomp__left">
            <div class="acomp__title">Nous accompagnons votre grossesse💛</div>
            <div class="acomp__description">
              <p>
                Préparer l’arrivée d’un bébé est une expérience merveilleuse, mais soyons
                francs, c’est aussi un processus au cours duquel on peut se poser de
                nombreuses questions et cela peut être stressant.
              </p>
              <p>
                Nos e-mails répondront à vos questions et serviront de rappel sympathique
                de votre calendrier de grossesse.
              </p>
            </div>
          </div>
          <div class="acomp__right">
            <img
              src="../../assets/img/covers/cover-fr-acompanamiento.jpg"
              alt="Préparer l’arrivée d’un bébé est une expérience merveilleuse, mais soyons francs, c’est aussi un processus au cours duquel on peut se poser de nombreuses questions et cela peut être stressant."
            />
          </div>
        </div>
      </div>
    </section>
    <section class="cta-yellow-fw">
      <div class="bb-container">
        <div class="cta-yellow-fw__cta">
          <div class="cta-yellow-fw__left">
            <div class="cta-yellow-fw__title">
              Et vous, vous êtes prête pour l’arrivée de votre bébé ?
            </div>
            <div class="cta-yellow-fw__actions">
              <bbButton
                label="Commencez votre liste"
                class="button--accent button--lg"
                @click="$router.push({ name: 'creez-votre-liste' })"
              />
              <div 
                class="link link--bold link--underline"
                @click="$router.push({ name: 'guest', params: { id: exampleListId } })"
              >
                Voir un exemple de liste
            </div>
            </div>
          </div>
          <div class="cta-yellow-fw__cover">
            <img src="../../assets/img/covers/cover-peluche-ropa-bb-comoda.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import bbButton from "../../components/buttons/bbButton.vue";

export default {
  name: "HomePageFR",
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            name: "HelloBB",
            operatingSystem: [
              //no estic segur del format per posar més d’un valor
              "ANDROID",
              "IOS",
            ],
            applicationCategory: "LifestyleApplication",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.5", //no aconsegueixo veure ratings, tu pots?
              ratingCount: "20", //no aconsegueixo veure ratings, tu pots?
            },
            offers: {
              "@type": "Offer",
              price: "0",
            },
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "HelloBB",
            legalName: "HelloBB S.L.",
            url: "https://www.hellobb.net/fr",
            logo: "https://www.hellobb.net/logo-hellobb-dark.svg", //posar URL on tenim el logo
            description:
              "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
            foundingDate: "2022",
            address: {
              "@type": "PostalAddress",
              streetAddress: "C/ Pompeu Fabra 36",
              addressLocality: "Tortosa",
              addressRegion: "Tarragona",
              postalCode: "43500",
              addressCountry: {
                "@type": "Country",
                name: "Spain", //en anglés millor?
              },
            },
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "customer support",
              email: "hola@hellobb.net",
            },
            sameAs: [
              "https://www.instagram.com/holahellobebe/",
              "https://www.facebook.com/holahelloBB",
              "https://www.pinterest.es/holahelloBB/",
              "https://www.crunchbase.com/organization/hellobb",
            ],
          },
        },
      ],
      title: "HelloBB | Una liste de naissance au top comme votre famille",
      meta: [
        {
          name: "description",
          content:
            "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
        },
        {
          itemprop: "image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          property: "og:url",
          content: "https://www.hellobb.net/fr",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "HelloBB | Una liste de naissance au top comme votre famille",
        },
        {
          property: "og:description",
          content:
            "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
        },
        {
          property: "og:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "HelloBB | Una liste de naissance au top comme votre famille",
        },
        {
          name: "twitter:description",
          content:
            "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
        },
        {
          name: "twitter:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net" },
        { rel: "alternate", href: "https://www.hellobb.net/fr", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/it", hreflang: "it" },
        { rel: "alternate", href: "https://www.hellobb.net", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-pe" },
      ],
    };
  },
  components: {
    bbButton,
    VueSlickCarousel,
  },
  data() {
    return {
      shopSlider: {
        slidesToShow: 8,
        arrows: false,
        autoplay: true,
        dots: false,
        variableWidth: false,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "20px",
        edgeFriction: 0.35,
        speed: 500,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            variableWidth: true,
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      reviewsSlider: {
        slidesToShow: 3,
        arrows: false,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    exampleListId() {
      return process.env.PREVIEW_LIST_ID;
    },
  },
};
</script>

<style lang="scss" scoped></style>
